/* *{
  font-family: ;
} */
.App {
  /* text-align: center; */
  margin: 0px;
  /* font-family: 'Times New Roman', Times, serif; */

}
button{
  font-family: 'Lato' !important;
}
table{
  font-family: 'Lato' !important;
}
.abc{
  position: relative;
  text-align: center;
  top:30%;
  font-size: 60px;
  color: rgba(255,255,255,.9);
  line-height: 68px;
  font-weight: lighter;
  
  margin-bottom: 100px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.playlist {
  max-width: 350px;
  margin: 50px auto;
}
.playlist input#first_name {
  margin-bottom: 20px;
}
/* h1{
  font-size: 64px;
line-height: 64px;
letter-spacing: 0.02em;
color: #000000;
} */
/* h5{
  font-size: 24px;
} */
/* h4{
  font-size: 32px;
} */

.playlist_box {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.rhap_play-pause-button{
color:#20d464 !important
}
.rhap_header{
  font-family: 'Lato' !important;
}
.rhap_time{
  color:#ffffff !important;
  font-family: 'Lato' !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.Loader{
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: fixed;
  z-index: 999999999;
}
.Loaderoverlay {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .5);
  position: fixed;
}
@media (max-width: 767px) {

.rhap_skip-button{
  display: none;
}
}
.mainloader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

/* song slider css */
.carousel {
  position: relative;
  width: 50%;
  height: 50px; 
  margin: 0 auto;
}

.carousel-content {
  position: relative;
  overflow: hidden;
  transition: width .4s;
  height: 100%;
}

.slide {
  height: 100%;
  background-color: black;
  position: absolute;
  z-index: 1;
  transition: left .4s cubic-bezier(.47,.13,.15,.89);
}

.slide-2 {
  background-color: green;
}

.slide-3 {
  background-color: red;
}

.slide-4 {
  background-color: purple;
}

.slide-5 {
  background-color: yellow;
}

.nav {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  background-color: rgba(150,150,150,.3);
  width: 20px;
  height: 20px;
  z-index: 2;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  outline: none;
  color: white;
  /* -webkit-user-select: none; */
}

.nav-left {
  left: -25px;
}

.nav-right {
  right: -25px;
}

.carousel-arrow-icon-left {
  margin-left: 5px;
  margin-top: 2px;
}

.carousel-arrow-icon-right {
  margin-left: 7px;
  margin-top: 2px;
}

.rhap_main {
  display: flex;
  flex-direction: column;
  flex: initial !important;
  width:80%;
  margin: auto;
  
}

.rhap_container{
  flex-direction:row !important;
  width: 100% !important;
  align-items: center;
  box-shadow: none !important;
  font-family: 'Lato' !important;
  
}
.rhap_progress-filled{
  background:linear-gradient(to right,#08e735 40%,#40e762 30%,#20d464 30% ) !important;
 /* background-color: #20d464 !important; */
}
.rhap_progress-indicator{
  background-color:#20d464 !important;
}

/* .rhap_play-pause-button{
 
} */

.fixedTop{
  position:fixed;
    top:0; 
    left:0;
    width: 100%; 
    box-shadow: rgb(0 0 0 / 20%) 0px 0px 3px 0px;

}
.stickyTop{
  z-index: 9999;
}

@media(max-width:1400px){
  .cardHead{
    width: 200px;
  }
  
}
@media(max-width:1280px){
  .cardHead{
    width:170px;
  }
  
}
@media(max-width:1024px){
  .cardHead{
    width:180px;
  }
  video{
        height: 300px !important;
  }
  
}
@media(max-width:820px){
  .cardHead{
    width:170px;
  }
  video{
        height: 250px !important;
  }
  
}
@media(max-width:770px){
  .cardHead{
    width:150px;
  }
  video{
        height: 240px !important;
  }
}
@media(max-width:500px){
  .cardHead{
    width:140px;
  }
  video{
        height: 200px !important;
  }
}
@media(max-width:480px){
  .cardHead{
    width:160px;
  }
  video{
        height: 180px !important;
  }
}
@media(max-width:380px){
  .cardHead{
    width:140px;
  }
  
}
@media(max-width:340px){
  .cardHead{
    width:130px;
  }
  
}

@media(max-width:320px){
  .cardHead{
    width:100px;
  }
  
}

.header{
  z-index: 20;
  position: absolute;
  left: 0;
  top: 0;
  height: 66px;
  width: 100%;
  background-color: #1e1e1e;
  border-bottom: 1px solid #707070;
}

.sidebar {
  position: absolute;
  left: 0px;
  top: 66px;
  width: 230px;
  height: calc(100% - 142px);
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #191919;
  padding-left: 30px;
}
.sidebar ul a{
  color: #bcbcbc;
}
.main{
  position: absolute;
  left: 230px;
  width: calc(100% - 230px);
  height: calc(100% - 142px);
  top: 66px;
  overflow-x: hidden;
    overflow-y: auto;
    background-color: #242424;
    color: #fff;
   
}
.main1{
  position: absolute;
 
  width:100%;
  height: calc(100% - 142px);
  top: 66px;
  overflow-x: hidden;
    overflow-y: auto;
    background-color: #fff;
    color: #242424;
   
}
.footerStyled{
  background-color: #2e2e2e;
  color: #fff;
}
.loader {
 
      position: absolute;
       left: 50%;
        top: 50%;
        transform: translate(-50%, -50%)
      
     }
     .loader1 {
 
      position: relative;
       left: 50%;
        top: 50%;
        transform: translate(-50%, -50%)
      
     }
     .css-i4bv87-MuiSvgIcon-root{
       width: 16px !important;
     }
     @media (max-width: 767px) {
    .sidebar{ display: none; }
    .main{ position: absolute;
      left: 0px;
      width: 100%;
      height:100%;
      top: 66px;
      overflow-x: hidden;
        overflow-y: auto;
        background-color: #242424;
    color: #fff;
      }
        .main3{
          position: absolute;
    left: 0px;
    width: 100%;
    height: 100%;
    top: 66px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #242424;
    color: #fff;
        }
        .main1 {
          position: absolute;
          width: 100%;
          height: calc(100% - 142px);
          top: 66px;
          overflow-x: hidden;
          overflow-y: auto;
          background-color: #fff;
          color: #242424;
      }
    }

    .modal-img{
      width: 60px;
    height: 60px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-size: 60% auto;
    background-position: center center;
    float: left;
    border-radius: 4px;
    }
    .playlist_box{
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
    .select_playlist{
      display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgb(156, 156, 156);
    padding: 15px 0 15px;
    position: sticky;
    top: 0px;
    background: #333;
    margin-bottom: 10px;

    }
    .select_playlist2{
      display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgb(156, 156, 156);
    padding: 15px 0 15px;
    position: sticky;
    top: 0px;
    background: #333;
    margin-bottom: 10px;

    }
    .search-box-input {
      background: #171717;
      border: 1px solid #3e3e3e;
    }
    .custom-dialog{
      

  position: fixed;
  inset: unset;
  background: rgba(0, 0, 0, 0.9);
  z-index: 610;
  width: 100%;
  font-size: 18px;
  line-height: 22px;
  bottom: 0;
  left: 0;

    }
    .rhap_container{
      padding: 0 15px !important;
    background: #000 !important;
    color: #fff !important;
    }
    .privacy-head h2{
      font-size: 37px;
      line-height: 24px;
      padding-top: 30px;
      padding-bottom: 12px;
      color: #000;
      margin-bottom: 13px;
      font-weight: bold;
    }
    .privacy-head h2 i{
      font-style: none;
    }
    .privacy-head p{
      text-align: left;
      word-break: break-word;
      color: #000000;
      font-size: 16px;
      line-height: 25px;
      font-weight: 500;
    }
    .privacy-head {
      max-width: 94vw;
      margin: auto;
      padding: 30px 0;
    }

    /* @media (min-width: 768px) { 
      .privacy-head{
        margin-left: 70%;
      }
    } */
    /* player style */
   /* .btn-icon{
      width: 25px;
    height: 25px;
    margin-right: 15px;
    background-size: auto 20px;
    transition: all .3s;
    }
    .btnPlayer{
      display: inline-block;
    border: none;
    padding: 0;
    background-color: #000;
    }
    .download-btn{
      background: url(/img/playerIcon/btn_download_normal-3E4FGEX5.svg);
      background-repeat: no-repeat;
    background-position: center center;
    }*/
    .like-btn{
      background: url(/img/playerIcon/btn_favorite_normal-G2OGBHK7.svg);
      background-repeat: no-repeat;
    background-position: center center;
    }
    .playlist-btn{
      background: url(/img/playerIcon/btn_addPlaylist_normal-TDECRTKN.svg);
      background-repeat: no-repeat;
    background-position: center center;
    }
    video{
      max-width: 100%;
      height: 400px;
      width: 100%;
      object-fit: cover;
    }
     .space-y-2 li a:hover span{
      color: #20d464;
    }
.space-y-2 li a:hover path{
      fill: #20d464 !important;
    }
    .space-y-2 li a svg{
      stroke: #bcbcbc;
    }
    .space-y-2 li a:hover svg{
      stroke: #20d464 !important;
    }
    .account-head {
    display: flex;
}
 .account-head span{
  color: #fff;
 }


 /*player styling*/
 .rhap_volume-controls{
  gap: 20px;
 }
 .rhap_progress-container{
  margin: 0 !important;
    position: absolute;
    top: -10px;
    width: 100vw;
    left: 0%;
 }
 .rhap_progress-section{
  justify-content: flex-end;
 }
 .rhap_stacked .rhap_controls-section{
  margin-top: 0px !important;
      width: 90%;
 }
 .rhap_progress-indicator{
  width: 10px !important;
    height: 10px !important;
        top: -4px !important;
    margin-left: -4px !important;
 }
 .rhap_progress-bar{
  height: 2px !important;
 }
 .rhap_time{
  font-size: 12px !important;
 }
 .rhap_controls-section{
      padding-bottom: 12px !important;
 }
 .rhap_main{
  width: 90% !important;
  padding-top: 8px;
 }
.rhap_download-progress{
  background-color: #3b4753 !important;
}
.player-f-btn:hover svg path{
  stroke: #20d464 !important;
}
.player-f-btn:hover svg path{
  fill: #20d464 !important;
}
/* @media(max-width:768px) {
  .player-f-btn{
    display:none;
  }
  .player-f-btn{
    display: block;
  }
} */

 /*sidebar scollbar styling*/
    .sidebar::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #000;
}

.sidebar::-webkit-scrollbar
{
  width: 8px;
  background-color: #000;
}

.sidebar::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #383838;
}
    

 /*main scrollbar styling*/

   .main::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #000;
}

.main::-webkit-scrollbar
{
  width: 8px;
  background-color: #000;
}

.main::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #605f5f;
}
  .grid-style{
            grid-template-columns: [first] 150px [line2] 10px [line3] 100px [end];
           
  }
  .follow-btn:hover{
    color: #20d464;
    border-color: #20d464 ;
  }
  a:hover {
    color:#20d464;
}
a:hover path{
  fill:#20d464 !important ;
}

.song-list-hover:hover{
      opacity: 1;
    background: rgba(216,216,216,.1);
        border-radius: 8px;
}
.song-fn-btn{
          background-color: #ffffff17;
}
.song-fn-btn:hover{
      background-color: rgba(255,255,255,.18);
}
.download-option-footer{
  display: none;
}
@media(max-width:768px) {
  .download-option{
    display:none;
  }
  .download-option-footer{
  display: flex;
}
}
.abouthead{
  font-family: 'Lato' !important;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
}
.about-main{
  min-height: 500px;
}


@media only screen and (max-width: 480px) and (min-width: 320px)  {
  .profile-head{
    display: block;
  }
}
.google-icon button {
  background-color: transparent !important;
  box-shadow: none !important;
}
.google-icon button span {
  color: #fff;
  font-size: 20px;
}
.google-icon button div {
  background: transparent !important;
}

.signup-form input:focus-visible{
  outline: none;
}
.catagories-head{
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-align: center;
}
.catagories-head.slider{
  width:90%;
}





/* ========================06-06-2022=============================== */
.font-latto{
  font-family: 'Lato' !important;
}
/*signin styling 09-06-1996*/
.social-links div div div div{
      background: transparent;
    border: none;
    color: white;
}

.google{
  background: transparent;
  border: none !important;
  color: white !important; 
}
.left-icons.flex.items-center.gap-6 {
  width: 100%;
  flex-wrap: wrap;
}
.left-icons a {
  width: 100%;
  background: #000;
  border-radius: 35px;
  display: flex;
  color: #fff;
  padding: 10px;
  text-align: center;
  justify-content: center;
}
.left-icons a img {
  width: 24px;
  margin-right: 10px;
}
.slick-slide { 
  height: 430px;
  overflow: hidden;
}